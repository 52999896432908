var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('div',{staticClass:"search"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("隔离解除")]),_c('span',[_vm._v(_vm._s(_vm.total_number)+"人")])]),_c('el-form',{staticClass:"right",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"关键字："}},[_c('el-input',{attrs:{"placeholder":"请输入姓名/电话"},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.getList}},[_vm._v("搜索")]),_c('el-button',{on:{"click":_vm.cancelSearch}},[_vm._v("清除搜索")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"row-key":"id"},on:{"row-click":_vm.getUserTemperature}},[_c('el-table-column',{attrs:{"prop":"shipper_info.equipment_no","label":"编号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"room_num","label":"位置","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellispsis"},[_vm._v(_vm._s(row.room_num))])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_name","label":"性别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sex ? "女" : "男")+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"current_temperature","label":"当前体温","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.current_temperature)?_c('span',[_vm._v(_vm._s(row.current_temperature)+"℃")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"maximum_temperature","label":"最高体温","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.maximum_temperature)?_c('span',{style:({
            color: row.maximum_temperature >= 37.3 ? '#FF0000' : '#1e92ff',
          })},[_vm._v(_vm._s(row.maximum_temperature)+"℃")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"age","label":"年龄","align":"center"}}),_c('el-table-column',{attrs:{"prop":"address","label":"现住址","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"ellispsis"},[_vm._v(_vm._s(row.address))])]}}])}),_c('el-table-column',{attrs:{"prop":"goods_name","label":"开始观察期","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.period_observation)?_c('span',[_vm._v(_vm._s(_vm.getDateformat(row.period_observation)))]):_c('span',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"online_status","label":"在/离线","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"online_status"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.online_status),expression:"row.online_status"}]}),_vm._v(" "+_vm._s(row.online_status ? "在线" : "离线")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"mobile","label":"电话","align":"center","width":"110"}}),_c('el-table-column',{attrs:{"prop":"goods_name","label":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{style:({ color: row.status ? '#FF0000' : '' })},[_vm._v(_vm._s(row.status ? "隔离中" : "解除隔离"))])]}}])}),_c('el-table-column',{attrs:{"prop":"isolation_point","label":"隔离点","align":"center","width":"120"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"#1e92ff"}},[_vm._v("隔离点")])]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"ellispsis"},[_vm._v(_vm._s(row.isolation_point))])]}}])}),_c('el-table-column',{attrs:{"prop":"shipper_info","label":"负责人","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"#1e92ff"}},[_vm._v("负责人")])]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.teacher_info.nickname)?_c('span',[_vm._v(_vm._s(row.teacher_info.nickname))]):_c('span',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"classify_name","label":"电话","align":"center","width":"110"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"#1e92ff"}},[_vm._v("电话")])]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.teacher_info.mobile)?_c('span',[_vm._v(_vm._s(row.teacher_info.mobile))]):_c('span',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"prop":"goods_name","label":"创建日期","align":"center","width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticStyle:{"color":"#1e92ff"}},[_vm._v("创建日期")])]},proxy:true},{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.last_login_time))+" ")]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":_vm.rows,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total_number},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"导入","visible":_vm.showImport,"width":"550px"},on:{"update:visible":function($event){_vm.showImport=$event}}},[_c('el-link',{attrs:{"type":"primary","href":"student.xlsx","download":"隔离人员模板.xlsx"}},[_vm._v("下载导入模板")]),_c('el-input',{staticClass:"upload",model:{value:(_vm.filePath),callback:function ($$v) {_vm.filePath=$$v},expression:"filePath"}},[_c('el-button',{attrs:{"slot":"append"},slot:"append"},[_vm._v(" 上传文件 "),_c('input',{ref:"inputer",attrs:{"type":"file"},on:{"change":_vm.getFile}})])],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showImport = !1}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.comfirmImport}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }